@import url('https://fonts.googleapis.com/css?family=DM+Sans:500,700&display=swap');

.box {
    width: 90vw;
    min-height: 400px;
    margin: 20px;
    margin-top: 30px;

    padding-bottom: 35px;
    position: relative;
    
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 0 10px 40px rgba(159, 162, 177, .8);
    overflow-wrap: break-word;
    word-wrap: break-word;
}


.box>h1 {
    color: black;
    font-size: 30px;
    font-weight: 700;
}

.box>h2 {
    color: black;
    font-size: 25px;
    font-weight: 700;
    font-style: normal;
}

.box>h3 {
    color: black;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
}

.box>* {
    color: #83818c;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;

    padding-left: 50px;
    padding-right: 50px;
    text-align: left;
}

.box li {
    margin-left: 50px;

}

.box li li {
    margin-left: 20px;

}

.box a {
    color: blue;
}

code {
    overflow-wrap: break-word !important;
}

@media (min-width: 1224px) {
    .box {
        width: 70vw;
    }
}
@import url(https://fonts.googleapis.com/css?family=DM+Sans:500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=DM+Sans:500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=DM+Sans:500,700&display=swap);
/* Navbar Text */
.navbar_nav__3PcoF {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    width: 90vw;
    max-width: 430px;

    position: relative;
    overflow: hidden;
    background-color: #fff;
    padding: 0 20px;
    border-radius: 40px;
    box-shadow: 0 10px 40px rgba(159, 162, 177, .8);
    
}
  
.navbar_navItem__1hrh2 {
    color: #83818c;
    padding: 20px;
    text-decoration: none;
    transition: .3s;
    
    z-index: 1;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    position: relative;
    font-size: 18px;  
}


@media (min-width: 580px) {
    .navbar_navItem__1hrh2 {
        margin: 0 6px;
    }
}

.navbar_navItem__1hrh2.navbar_active__1d4Yc.navbar_orange__fAEKT {
    color: orange;
}

.navbar_navItem__1hrh2.navbar_active__1d4Yc.navbar_green__3FH0Z {
    color: green;
}

.navbar_navItem__1hrh2.navbar_active__1d4Yc.navbar_red__1YsI7 {
    color: red;
}

.navbar_navItem__1hrh2.navbar_active__1d4Yc.navbar_purple__1bEiL {
    color: purple;
}

.navbar_navItem__1hrh2:not(.navbar_active__1d4Yc).navbar_orange__fAEKT:hover { 
    color:orange; 
}

.navbar_navItem__1hrh2:not(.navbar_active__1d4Yc).navbar_green__3FH0Z:hover { 
    color:green; 
}

.navbar_navItem__1hrh2:not(.navbar_active__1d4Yc).navbar_red__1YsI7:hover { 
    color:red; 
}

.navbar_navItem__1hrh2:not(.navbar_active__1d4Yc).navbar_purple__1bEiL:hover { 
    color:purple; 
}
  
/* Navbar Indicator */
.navbar_navIndicator__3wR6l {
    position: absolute;
    left: 0;
    bottom: 0;
    transition: .4s;
    height: 5px;
    z-index: 1;
    border-radius: 8px 8px 0 0;
}

.navbar_navItem__1hrh2::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #dfe2ea;
    border-radius: 8px 8px 0 0;
    opacity: 0;
    transition: .3s;
}




.navbar_navItem__1hrh2.navbar_active__1d4Yc:before {
    opacity: 1;
    bottom: 0;
}

.navbar_navItem__1hrh2.navbar_active__1d4Yc.navbar_orange__fAEKT::before {
    background-color: orange;
}

.navbar_navItem__1hrh2.navbar_active__1d4Yc.navbar_green__3FH0Z::before {
    background-color: green;
}

.navbar_navItem__1hrh2.navbar_active__1d4Yc.navbar_red__1YsI7::before {
    background-color: red;
}

.navbar_navItem__1hrh2.navbar_active__1d4Yc.navbar_purple__1bEiL::before {
    background-color: purple;
}


.navbar_navItem__1hrh2:not(.navbar_active__1d4Yc):hover:before {
    opacity: 1;
    bottom: 0;
}

.navbar_navItem__1hrh2:not(.navbar_active__1d4Yc).navbar_orange__fAEKT:hover:before {
    background-color: orange;
}

.navbar_navItem__1hrh2:not(.navbar_active__1d4Yc).navbar_green__3FH0Z:hover:before {
    background-color: green;
}

.navbar_navItem__1hrh2:not(.navbar_active__1d4Yc).navbar_red__1YsI7:hover:before {
    background-color: red;
}

.navbar_navItem__1hrh2:not(.navbar_active__1d4Yc).navbar_purple__1bEiL:hover:before {
    background-color: purple;
}

article {
    width: 80vw;
    max-width: 450px;
    min-height: 370px;

    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;

    border-radius: 40px;
    background-color: #fff;
    box-shadow: 0 10px 40px rgba(159, 162, 177, .8);
    position: relative; /*For .readmore positioning*/
    margin: 20px; /*For space in between on desktop*/
} 

.blog_readMore__1I5E1 {
    color: rgb(0,0,254);
    position: absolute;
    bottom: 20px;
    right: 50px;
}

article > h2 {
    color: red;
}


@media (min-width: 1224px) {

    .blog_blog__1cXZn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 0;
    }

    .blog_content__35lq2 {
        width: 80vw;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .blog_content__35lq2 h1 {
        margin-top: 10px;
    }

}












.single_box__NMw_k {
    width: 90vw;
    min-height: 400px;
    margin: 20px;
    margin-top: 30px;

    padding-bottom: 35px;
    position: relative;
    
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 0 10px 40px rgba(159, 162, 177, .8);
    overflow-wrap: break-word;
    word-wrap: break-word;
}


.single_box__NMw_k>h1 {
    color: black;
    font-size: 30px;
    font-weight: 700;
}

.single_box__NMw_k>h2 {
    color: black;
    font-size: 25px;
    font-weight: 700;
    font-style: normal;
}

.single_box__NMw_k>h3 {
    color: black;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
}

.single_box__NMw_k>* {
    color: #83818c;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;

    padding-left: 50px;
    padding-right: 50px;
    text-align: left;
}

.single_box__NMw_k li {
    margin-left: 50px;

}

.single_box__NMw_k li li {
    margin-left: 20px;

}

.single_box__NMw_k a {
    color: blue;
}

code {
    overflow-wrap: break-word !important;
}

@media (min-width: 1224px) {
    .single_box__NMw_k {
        width: 70vw;
    }
}
body {
    background-image: url("https://www.toptal.com/designers/subtlepatterns/patterns/debut_light.png");
    text-align: center;

    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    color: #83818c;
    outline: 0;
} 
  
main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}
  
section {
    width: 70vw;
    min-height: 30vh;
    margin-top: 30px;

    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 10px 40px rgba(159, 162, 177, .8);
    padding-bottom: 30px;
    
    font-weight: 500;
    padding-left: 50px;
    padding-right: 50px;
}
  
  
h1 {
    color: black;
    font-size: 30px;
    padding-top: 20px;
    font-weight: 700;
}

h2 {
    color: black;
    font-size: 25px;
    font-style: italic;
    text-decoration: underline;
}
  
/* Icons and Links Styling */
.icons li {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icons li i {
    font-size: 40px;
    margin-bottom: 10px;
}

.icons li a {
    font-size: 20px;
    margin-left: 10px;
}

a, button {
    color: inherit; 
    text-decoration: inherit;
    font-size: inherit;
    font-weight: inherit;
}

button {
    background: none;
    border: none;
    font-family: inherit;
    font-style: inherit;
    padding: inherit;
}


.italicLink {
    font-style: italic;
}

.blueLink {
    color: blue;
}

/* Form Styling */
label {
    display: flex;
    flex-flow: column;
    margin-bottom: 15px;
}
  
input, textarea {
    font-size: 17px;
    font-family: inherit;
    font-weight: 500;
    padding: 5px;

    border: 1px solid #83818c; 
    border-radius: 3px;
    margin-top: 5px;
}

textarea {
    resize: none;
}

/* Media Queries */

@media (min-width: 1224px) {
    section {
        width: 65vw;
    }

    section li {
        margin-left: 1em; 
    }
}



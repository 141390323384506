@import url('https://fonts.googleapis.com/css?family=DM+Sans:500,700&display=swap');

  /* Navbar Text */
.nav {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    width: 90vw;
    max-width: 430px;

    position: relative;
    overflow: hidden;
    background-color: #fff;
    padding: 0 20px;
    border-radius: 40px;
    box-shadow: 0 10px 40px rgba(159, 162, 177, .8);
    
}
  
.navItem {
    color: #83818c;
    padding: 20px;
    text-decoration: none;
    transition: .3s;
    
    z-index: 1;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    position: relative;
    font-size: 18px;  
}


@media (min-width: 580px) {
    .navItem {
        margin: 0 6px;
    }
}

.navItem.active.orange {
    color: orange;
}

.navItem.active.green {
    color: green;
}

.navItem.active.red {
    color: red;
}

.navItem.active.purple {
    color: purple;
}

.navItem:not(.active).orange:hover { 
    color:orange; 
}

.navItem:not(.active).green:hover { 
    color:green; 
}

.navItem:not(.active).red:hover { 
    color:red; 
}

.navItem:not(.active).purple:hover { 
    color:purple; 
}
  
/* Navbar Indicator */
.navIndicator {
    position: absolute;
    left: 0;
    bottom: 0;
    transition: .4s;
    height: 5px;
    z-index: 1;
    border-radius: 8px 8px 0 0;
}

.navItem::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #dfe2ea;
    border-radius: 8px 8px 0 0;
    opacity: 0;
    transition: .3s;
}




.navItem.active:before {
    opacity: 1;
    bottom: 0;
}

.navItem.active.orange::before {
    background-color: orange;
}

.navItem.active.green::before {
    background-color: green;
}

.navItem.active.red::before {
    background-color: red;
}

.navItem.active.purple::before {
    background-color: purple;
}


.navItem:not(.active):hover:before {
    opacity: 1;
    bottom: 0;
}

.navItem:not(.active).orange:hover:before {
    background-color: orange;
}

.navItem:not(.active).green:hover:before {
    background-color: green;
}

.navItem:not(.active).red:hover:before {
    background-color: red;
}

.navItem:not(.active).purple:hover:before {
    background-color: purple;
}
